<template>
  <div class="phase2">
    <vue-gallery
      id="gallery-phase2-update"
      :images="images"
      :index="index"
      @close="index = null"
    ></vue-gallery>

    <div>
      <section>
        <div class="bsat__title">{{ $t("title") }}</div>
        <div class="bsat__paragraph">{{ $t("paragraph") }}</div>
      </section>

      <section>
        <div class="bsat__subtitle mb-4">{{ $t("subtitle") }}</div>
        <div class="bsat__paragraph">{{ $t("paragraph2") }}</div>
        <ReadMore>
          <div class="mb-4">
            <ul>
              <li
                v-for="(item, i) in whoToinvolveList"
                :key="i"
                v-text="item"
              ></li>
            </ul>
          </div>
        </ReadMore>
      </section>
    </div>

    <section>
      <div class="bsat__subtitle mb-4">{{ $t("overviewTitle") }}</div>
      <div class="bsat__paragraph">{{ $t("overviewParagraph") }}</div>
      <!-- <div class="bsat__paragraph">{{ $t("overviewParagraph2") }}</div> -->
      <v-hover v-slot="{ hover }">
        <div
          style="position: relative"
          :style="hover ? 'opacity: 0.7; transition: opacity 0.5s;' : ''"
          class="mt-15"
          @click="index = 0"
        >
          <v-img
            contain
            height="550px"
            src="@/assets/icons/updateP2.png"
          ></v-img>
          <transition name="fade">
            <div v-if="hover" class="hover">{{ $t("clickToEnlarge") }}</div>
          </transition>
        </div>
      </v-hover>
    </section>

    <div class="mt-10">
      <i18n path="action.text" tag="div" class="bsat__paragraph">
        <template #title>
          <strong>{{ $t("action.title") }}</strong>
        </template>
      </i18n>
    </div>

    <div>
      <i18n path="material.text" tag="div" class="bsat__paragraph">
        <template #title>
          <strong>{{ $t("material.title") }}</strong>
        </template>
      </i18n>
    </div>

    <div class="mb-10">
      <a :href="materialFile" target="_blank" download>
        <v-btn
          class="bsat__btn"
          color="dark"
          dark
          depressed
          width="150"
          height="26"
          >{{ $t("download") }}</v-btn
        >
      </a>
    </div>

    <Divider></Divider>

    <div class="bsat__subtitle">{{ $t("power4BioExperience") }}</div>
    <!--************************* TABS **************************** -->
    <div class="phase2__tabs">
      <v-tabs
        v-model="tab"
        class="bsat__tabs"
        background-color="transparent"
        slider-color="dark"
        color="dark"
        show-arrows
        center-active
      >
        <v-tab
          v-for="(item, i) in footerTabs"
          :key="i"
          class="mr-4 mr-sm-8"
          :style="
            $vuetify.breakpoint.xsOnly ? 'min-width: 90px' : 'min-width: 160px'
          "
        >
          {{ $t(item.tab) }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="py-4">
        <!-- ********** REAL EXAMPLES TAB ******* -->
        <v-tab-item>
          <div class="bg-off_white text-default">
            <div class="bsat__subtitle">{{ $t("realExamples.cardTitle") }}</div>
            <div class="bsat__paragraph">
              {{ $t("realExamples.paragraph") }}
            </div>
            <v-list dense three-line>
              <v-list-item
                v-for="(item, i) in realExamples"
                :key="i"
                class="card my-2 px-0"
              >
                <v-list-item-content>
                  <v-row>
                    <v-col cols="12" md="3">
                      <img
                        class="elevation-2 rounded"
                        style="max-width:100% !important;"
                        :src="require(`@/assets/icons/regions/${item.icon}`)"
                        @click="index = i + 1"
                      />
                    </v-col>
                    <v-col cols="12" md="9">
                      <v-list-item-title
                        v-text="item.title"
                        class="card-text"
                      ></v-list-item-title>
                      <v-list-item-subtitle>
                        <a :href="item.url">{{ item.url }}</a>
                      </v-list-item-subtitle>
                      <div
                        class="card-description"
                        v-text="item.paragraph"
                      ></div>
                      <div
                        v-if="item.paragraph2"
                        class="card-description"
                        v-text="item.paragraph2"
                      ></div>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <!-- <v-row>
              <v-spacer></v-spacer>
              <v-pagination
                class="pagination"
                color="dark"
                v-model="page"
                :length="3"
              ></v-pagination>
            </v-row> -->
          </div>
        </v-tab-item>
        <!-- ********** NOTES TAB ******* -->
        <v-tab-item>
          <div class="bg-off_white">
            <div class="bsat__subtitle">{{ $t("notes.note1.title") }}</div>
            <div class="bsat__paragraph">
              {{ $t("notes.note1.paragraph") }}
            </div>
            <div class="mb-4">
              <ul>
                <li
                  v-for="(item, i) in note1UnorderedList"
                  :key="i"
                  v-text="item"
                ></li>
              </ul>
            </div>
            <div class="bsat__subtitle">{{ $t("notes.note2.title") }}</div>
            <div class="bsat__paragraph">
              {{ $t("notes.note2.paragraph") }}
            </div>
            <div class="bsat__subtitle">{{ $t("notes.note3.title") }}</div>
            <div class="bsat__paragraph">
              {{ $t("notes.note3.paragraph") }}
            </div>
            <div class="bsat__subtitle">{{ $t("notes.note4.title") }}</div>
            <div class="bsat__paragraph">
              {{ $t("notes.note4.paragraph") }}
            </div>
          </div>
        </v-tab-item>

        <!-- ********** OPINIONS TAB ******* -->
        <v-tab-item>
          <v-card flat color="off_white">
            <v-card-text class="px-0">
              <v-list dense class="bg-off_white">
                <v-list-item
                  dense
                  class="text-default"
                  v-for="(item, i) in currentOpinions"
                  :key="i"
                >
                  <v-list-item-avatar size="40">
                    <v-img src="@/assets/icons/profile.png"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.subtitle
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-row>
                <v-spacer></v-spacer>
                <v-pagination
                  class="pagination"
                  color="dark"
                  v-model="opinionsPage"
                  :length="2"
                ></v-pagination>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "action": {
      "text": "{title} Consult and follow these guidelines which explain in detail how to implement every step in phase 2",
      "title": "Action: "
    },
    "material": {
      "text": "{title} “Establishing Bioeconomy Hubs as stakeholder platforms to support Regional Bioeconomy Strategies”",
      "title": "Material available: "
    },
    "notes": {
      "note1": {
        "paragraph": "Despite several interpretations of the word “hub” can arise, POWER4BIO project considers the following points before proceeding with the hub’s implementation:",
        "title": "Note 1",
        "unorderedList": [
          "A hub is a knowledge network in which discussion and planning about a topic -bioeconomy in our specific case- are carried out in order to boost their promotion at different levels (society, industry, academia...) and scales (local, regional, national, European...).",
          "A hub does not necessarily need to be constituted as a legal entity.",
          "A hub has to be acknowledged as the driver entity for the bioeconomy regional initiative and represents the interests of the related bioeconomy regional stakeholders. ",
          "A hub can be formed by different type of entities, such as clusters, industrial partners, universities, SMEs, NGOs, Research Centers, Public Bodies, Governments",
          "It would be helpful for the process of generating a vision and a bioeconomy strategy to maintain engaged during the whole process the entities invited to be part of the hub. As well as to keep the hub open for others to join, according to the needs of the hub."
        ]
      },
      "note2": {
        "paragraph": "A Hub will thrive if all stakeholders involved notice their input is heard and taken into consideration. As well as, with an equilibrated representation of different entities that enrich the perspective on the topic and promote cross-sectoral and/or transversal knowledge exchange.",
        "title": "Note 2"
      },
      "note3": {
        "paragraph": "After the development of a regional position paper on bioeconomy and the creation of a dedicated “core group” within the regional administration, the definition of an action plan related to bioeconomy in the region was quite challenging, due to the difficulty to clearly list possible integrated value chains and to pro-actively engage stakeholders, especially from the primary sector. In this sense, the catalogue of bio-based solutions and best practice examples, together with the business model analysis for bio-based solutions supported the analysis of the regional potential and gave practical examples helpful in involving the stakeholders",
        "title": "Note 3"
      },
      "note4": {
        "paragraph": "Guidelines to establish RBHs are mainly focused on a bottom-up approach, where the involvement of regional policy makers comes as an important step. Anyway, in regions where the bioeconomy strategy idea started directly from the regional administrations, the guidelines were useful to define the “core group”, engaging representatives of different regional directorates, as well as to better identify and structure the involvement of the stakeholders.",
        "title": "Note 4"
      },
      "tabTitle": "Notes"
    },
    "opinions": {
      "list": [
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 1"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 2"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 3"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 4"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 5"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 6"
        }
      ],
      "title": "Opinions"
    },
    "overviewParagraph": "This phase consists of a series of steps which range from establishing a Regional Bioeconomy Hub, expanding the RBH thorough stakeholder involvement and finally, building the regional bioeconomy vision and prioritization of action fields. The launch of the new Regional Blioeconomy Hubs will demand the following steps: 1) stakeholders’ identification, 2) stakeholders’ analysis and 3) stakeholders’ engagement. following the figure below.",
    "overviewParagraph2": "At the end of this step, you will get a vision document which will consist of a general definition of the bioeconomy concept in your region, including its scope, and potentially an implementation´s deadline",
    "overviewTitle": "Overview of the steps",
    "paragraph": "The aim of this phase is to create, consolidate and achieve a sustained, long-lasting group of key stakeholders and set a common and co-created regional Bioeconomy vision between them. In order to do this, a Regional Bioeconomy Hub (RBHs) could be established. In case your region accounts for an equivalent structure to the proposed RBH, the following phases described in this Phase 2 would allow you to assess the extent to which the on-going structure could be enlarged and improved in view to the new scenarios to assess in your region.",
    "paragraph2": "The involvement of actors should be based on regions initial analysis (Phase 1) and the interest of the region, always considering a Quadruple Helix approach. That is, the involvement of industry, R&D and academia, policy, and civil society. Some of those actors might be reached through:",
    "power4BioExperience": "The POWER4BIO experience",
    "realExamples": {
      "cardTitle": "5 RBHs were developed in POWER4BIO",
      "examples": [
        {
          "icon": "LVIV.jpg",
          "paragraph": "In Lviv the Forest Sector Council (FSC), which dates back to 2008, has serve as the base for the RBH and the development of the regional bioeconomy strategy, given the existent stakeholder network, built trusts and collective experience. During the establishment of Lviv RBH, main efforts were directed to diversify the FSC stakeholders, based on the recommendation of POWER4BIO guideline. Particularly to attract small businesses and other relevant sectors for the regional bioeconomy. The engagement of most relevant stakeholders from agriculture, food and forest sector was successful, resulting in a collaborative SWOT analysis with representatives of the three main sectors of interest and the co-creation of the regional bioeconomy vision by May of 2019. As well as, the decision to integrate the Bioeconomy Strategy within other relevant regional documents, for instance, the Lviv region Development Strategy 2027.",
          "title": "Lviv, Ukraine"
        },
        {
          "icon": "MAZOVIA.jpg",
          "paragraph": "Mazovia region is another example of a bottom-up process of RBH development. The Mazovia Energy Efficiency Cluster, originally created by Mazovia Energy Agency and that engages representatives of local governments, research centres, small and medium enterprises, served as a basis for the RBH. A coordination group was initially developed within the Cluster for engagement activities. These were carried out by inviting key stakeholders, complementary to the energy sector and interested in collaborating in the regional bioeconomy strategy.  Currently, the RBH includes diverse constellation of stakeholders organized in working groups of agriculture raw materials, forest raw materials, bio-waste and their treatment and regional development. ",
          "title": "Mazovia, Poland"
        },
        {
          "icon": "NITRA.jpg",
          "paragraph": "The RBH in Nitra region was build upon the already existing Bioeconomy Cluster (BEC) founded in 2015, which aim is to promote cooperation in the field of innovation, mutual exchange of information be-tween cluster members and linking practice with R&D. It involves mainly representatives of the business sector (agricultural farms and food processors), R&D actors, academia, regional and local governments as well as tertiary sphere. Placing focus on crosscutting and cross-sectoral cooperation between areas such as, energy, waste and environment, Information and Communications Technology (ICT), plastic industry, engineering, between others.",
          "paragraph2": "During the RBH formation, the cluster started to mobilize towards the national level, beginning with the newly develop “Platform for bioeconomy in Slovakia”, endorsed by the Ministry of Agriculture and the guiding aims of the BIOEAST Initiative. This, to enlarge the activities, knowledge base and collaborations that the cluster might facilitate, with actors outside of the Nitra region. Likewise, the Ministry of Agriculture participates actively, designating representatives, which has positioned Nitra´s RBH as a support platform for the regional as well as national bioeconomy strategy, while generating synergies between POWER4BIO and BIOEASTUP project. ",
          "title": "Nitra, Slovakia"
        },
        {
          "icon": "SOUTH_BOHEMIA.jpg",
          "paragraph": "The University of South Bohemia (USB), partner within POWER4BIO has been the leading institution for the bottom-up initiative to develop the regional bioeconomy strategy. Starting from establishing the RBH, which required from the university to first identify key stakeholders and map the key bioeconomy sectors within the region. Adapting some of the resources provided in the RBH guideline, it was possible to identify the main areas of interest for the regional bioeconomy and the RBH consolidated a core team within six months. Other non-core team institutions have signed letters of commitment to the RBH, such as the Regional Agrarian Chamber of the South Bohemian Region (RAK JK), National Cluster Association (NCA), South Bohemian Agency for Support to Innovative Enterprising (JAIP) and the Czech Biogas Association (CzBA). ",
          "title": "South Bohemia, Czech Republic"
        },
        {
          "icon": "SOUTHERN_GREAT_PLAIN.jpg",
          "paragraph": "Southern Great Plain was before POWER4BIO interested in developing a Bioeconomy cooperation platform and used the resources available in the project to boost this initiative so that the RBH was developed as the Hungarian Bioeconomy Cluster. The process started with a top-down approach, as a cooperation between the Ministry of Agriculture, the Hungarian Chamber of Agriculture - already working together in the BIOEAST initiative since 2016 – with the Ministry of Innovation and Technology and Bay Zoltan Nonprofit Ltd (BZN) representing the region within POWER4BIO. The initiative reached initial funding from the Ministry of Innovation and Technology to set the cluster. Once the legal and administrative consolidation process finalized, the Hungarian Bioeconomy Cluster was officially established by July 2019. Expanding the cluster includes now activities to involve networks of feedstock producers, research and academia, and industrial innovation.",
          "title": "Southern Great Plain, Hungary"
        }
      ],
      "paragraph": "In the first year of POWER4BIO the five CEE regions participants of the project established their RBHs, using the recommended RBH guideline, as well as adapting the resources there provided to their specific conditions and needs. Some regions used existing platforms if already consolidated, such as the Forest Sector Council in Lviv (Ukraine), the Mazovia Energy Efficiency Cluster (Poland) and the Bioeconomy Cluster in Nitra (Slovakia). While other regions generated their RBH as a brand new platform to bring together the regional bioeconomy community in the absence of a previously existent one, as it is the case of South Bohemia (Czech Republic) and Southern Great Plain (Hungary). ",
      "tabTitle": "Real Examples"
    },
    "subtitle": "Who to involve in this phase?",
    "title": "Phase 2: Involvement of stakeholders through Regional Bioeconomy Hubs (RBHs)",
    "unorderedList": [
      "Representative of existing bioeconomy or bioeconomy related clusters ",
      "Industrial/sectorial associations",
      "Universities and their research groups with focus on bioeconomy activities as well as researching in topics of agriculture resources, forest resources, waste utilisation/valorisation, biochemicals, biotechnology, between others",
      "Research centres, technological parks or pilot plants in the region",
      "Policy makers. Ideally, it is encouraged to establish  an interdepartmental government group from areas such as agriculture, forest, water, regional development, economic affairs, energy, R&D and innovation between others.",
      "NGOs and other citizens representatives"
    ]
  }
}
</i18n>

<script>
import ReadMore from "@/components/ReadMore";
import VueGallery from "vue-gallery";
import Divider from "@/components/Divider";
import { getArrayPiece } from "@/utils/helpers";

export default {
  name: "Phase2",
  components: {
    ReadMore,
    VueGallery,
    Divider
  },
  data() {
    return {
      index: null,
      images: [
        require("@/assets/icons/updateP2.png"),
        require("@/assets/icons/regions/LVIV.jpg"),
        require("@/assets/icons/regions/MAZOVIA.jpg"),
        require("@/assets/icons/regions/NITRA.jpg"),
        require("@/assets/icons/regions/SOUTH_BOHEMIA.jpg"),
        require("@/assets/icons/regions/SOUTHERN_GREAT_PLAIN.jpg")
      ],
      page: 1,
      opinionsPage: 1,
      tab: 0,
      materialFile: require("@/assets/files/1_2_1_Establishing_Bioeconomy_Hubs_as_stakeholder_platforms_to_support_Regional_Bioeconomy_Strategies.docx")
        .default,
      footerTabs: [
        { tab: "realExamples.tabTitle" },
        { tab: "notes.tabTitle" }
        // { tab: "opinions.title" }
      ],
      phase2: [
        { component: "Step1" },
        { component: "Step2" },
        { component: "Step3" },
        { component: "Step4" }
      ]
    };
  },
  computed: {
    whoToinvolveList() {
      return Object.values(this.$t("unorderedList"));
    },

    realExamples() {
      return Object.values(this.$t("realExamples.examples"));
    },
    currentRealExamples() {
      return getArrayPiece(this.realExamples, this.page, 2);
    },
    opinions() {
      return Object.values(this.$t("opinions.list"));
    },
    currentOpinions() {
      return getArrayPiece(this.opinions, this.opinionsPage, 3);
    },
    note1UnorderedList() {
      return Object.values(this.$t("notes.note1.unorderedList"));
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";

.phase2 {
  margin-top: 20px;

  .blueimp-gallery {
    padding: 40px;
  }

  .v-list {
    background-color: $off_white;
  }

  .pagination {
    .v-pagination__item {
      background: $inactive_grey;
      min-width: 18px !important;
      height: 20px !important;
      font-size: 10px;
      margin: 0px 3px;
      box-shadow: none !important;
    }
    .v-pagination__navigation {
      background: $inactive_grey;
      min-width: 18px !important;
      width: 18px !important;
      height: 20px !important;
      font-size: 10px;
      box-shadow: none !important;
    }
    .v-pagination__item .v-pagination__item--active {
      box-shadow: none;
    }
    .mdi-chevron-left {
      font-size: 12px;
    }
    .mdi-chevron-right {
      font-size: 12px;
    }
  }

  .v-window {
    background-color: $off_white;
  }

  &__tabs {
    margin-top: 40px;
    margin-bottom: 200px;

    .bsat__paragraph {
      font-size: 14px;
      letter-spacing: 0;
    }
  }

  .v-tab {
    font-family: $font-family;
    text-transform: none;
    letter-spacing: normal;
    justify-items: start;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .steps {
    &__title {
      font-family: $font-family;
      color: $light_green;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.6;
      letter-spacing: 0.2px;
      text-align: left;
    }

    &__content {
      background-color: #ffffff;
      border-radius: 8px;
      border: 1px solid $light_green;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
    .v-stepper__header {
      background-color: $off_white;
      box-shadow: none;
    }

    .v-stepper__step--active {
      .v-stepper__step__step {
        align-items: center;
        border-radius: 50%;
        display: inline-flex;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        height: 36px;
        min-width: 36px;
        width: 36px;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        background-color: $off_white !important;
        border: 4px solid $light_green !important;
        border-radius: 50%;
        color: $light_green !important;
      }
    }

    .v-stepper__step--inactive {
      .v-stepper__step__step {
        align-items: center;
        border-radius: 50%;
        display: inline-flex;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        height: 36px;
        min-width: 36px;
        width: 36px;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        background-color: $off_white !important;
        border: 4px solid $inactive_grey !important;
        border-radius: 50%;
        color: $inactive_grey !important;
      }
    }

    .v-stepper__step__step {
      align-items: center;
      border-radius: 50%;
      display: inline-flex;
      font-size: 16px;
      font-weight: bold;
      justify-content: center;
      height: 36px;
      min-width: 36px;
      width: 36px;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      background-color: $off_white !important;
      border: 4px solid $inactive_grey !important;
      border-radius: 50%;
      color: $inactive_grey !important;
    }
    .v-stepper__step__step:hover {
      cursor: pointer;
    }
  }

  .card {
    background-color: $off_white;

    a {
      text-decoration: none;
      color: $dark !important;
      font-size: 9px;
    }

    .v-list-item__title {
      color: $dark;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
    }

    &-description {
      margin-top: 12px;
      color: $dark;
      font-family: $font-family;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: justify;
    }

    .image__custom {
      object-fit: cover;
      height: 250px;
    }
  }
}
.hover {
  position: absolute;
  text-align: center;
  color: #fff;
  top: 50%;
  left: calc(50% - 80px);
  opacity: 1;
  width: 200px;
  height: auto;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.8);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transition: opacity 0.5s;
}
</style>
